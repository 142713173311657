import { RequestState } from "../../../../../backend/common/states";

export const statusTextMap = new Map<
  RequestState | string | null | undefined,
  string
>([
  [RequestState.AWAITING_SHOP_ACCEPTANCE, "Awaiting Acceptance"],
  [RequestState.SCHEDULED_PICKUP, "Scheduled Dropoff"],
  [RequestState.IN_SHOP, "In Shop"],
  [RequestState.IN_SHOP_AWAITING_APPROVAL, "Awaiting Approval"],
  [RequestState.IN_PROGRESS, "Work In Progress"],
  [RequestState.AWAITING_PAYMENT, "Invoiced"],
  [RequestState.INVOICE_APPROVED, "Invoiced"],
  [RequestState.PAID, "Invoiced"],
  [RequestState.SCHEDULED_DROPOFF, "Scheduled Pickup"],
  [RequestState.DELIVERY_IN_PROGRESS, "Completed"],
  [RequestState.CLOSED, "Completed"],
  [RequestState.ASSIGNED, "Awaiting Estimate"],
  [RequestState.AWAITING_APPROVAL, "Awaiting Approval"],
  [RequestState.APPROVED, "Estimate Approved"],
  [RequestState.INSURANCE_INVOICE_APPROVED, "Insurance Invoice Approved"],
  [undefined, "Unknown"],
  [null, "Unknown"],
]);

export const ctaStatuses: (RequestState | string)[] = [
  RequestState.AWAITING_SHOP_ACCEPTANCE,
  RequestState.ASSIGNED,
];
