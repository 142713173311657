import { useCallback, useEffect, useState } from "react";

import { Box, Button, Chip, Typography } from "@mui/material";
import {
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import UnreadMessageBadge from "shared/components/CollapsibleTable/UnreadMessageBadge";
import { generateOrderBy } from "shared/components/CollapsibleTable/utils";
import DataGridTable from "shared/components/DataGridTable/DataGridTable";
import {
  createStyledLinkCell,
  renderVehicleImageCell,
  useDataGridSortHandler,
} from "shared/components/DataGridTable/utils";
import ErrorMessage from "shared/components/ErrorMessage";
import TextWithTooltip from "shared/components/tooltips/TextWithTooltip";
import { Order_By, useGetAllRequestsShopQuery } from "shared/generated/graphql";
import { getPrettyString, getPrettyTime } from "shared/lib/utils";
import { useAuth } from "shared/providers/AuthProvider";
import {
  UnreadRequestsProvider,
  useUnreadRequests,
} from "shared/providers/UnreadRequestsProvider";

import { WorkflowType } from "../../../../../backend/common/workflow.types";

import { getSearchFilters } from "./requestUtils";
import { ctaStatuses, statusTextMap } from "./util";

const columns: GridColDef[] = [
  {
    field: "Image",
    headerName: "",
    sortable: false,
    renderCell: renderVehicleImageCell,
  },
  {
    field: "id",
    headerName: "Order",
    hideable: false,
    renderCell: (params) => {
      const LinkCell = createStyledLinkCell(
        (params) => `/requests/${params.value}`
      );
      return (
        <UnreadMessageBadge
          content={LinkCell(params)}
          unreadMessageCount={params.row.unreadMessageCount}
        />
      );
    },
  },
  { field: "vehicle.year", headerName: "YMM" },
  { field: "vehicle.plate", headerName: "License Plate" },
  { field: "vehicle.mileage", headerName: "Mileage" },
  {
    field: "fleet.name",
    headerName: "Requested By",
    renderCell: (params: GridRenderCellParams) => {
      return params.row.workflowType === WorkflowType.SAAS
        ? `${params.row.fleet?.name}`
        : "ServiceUp";
    },
  },
  { field: "createdAt", headerName: "Date & Time" },
  {
    field: "status",
    headerName: "Status",
    minWidth: 200,
    renderCell: (params: GridRenderCellParams) =>
      ctaStatuses?.includes(params.value) ? (
        <Button
          variant="contained"
          size="small"
          href={`requests/${params.row.id}`}
        >
          <TextWithTooltip
            text={statusTextMap.get(params.value)}
            variant="inherit"
          />
        </Button>
      ) : (
        <Chip
          variant="outlined"
          size="small"
          label={statusTextMap.get(params.value)}
        />
      ),
  },
];

export default function RequestsDataGridDashboard() {
  const tableApiRef = useGridApiRef();
  const { shopId } = useAuth();
  const { getUnreadMessagesCount, setRequestIds } = useUnreadRequests();
  const [searchTerm, setSearchTerm] = useState("");
  const [yesterday] = useState(
    () => new Date(Date.now() - 24 * 60 * 60 * 1000)
  );
  const { sortField, sortDirection, handleSortModelChange } =
    useDataGridSortHandler({ field: "id", direction: Order_By.Desc });

  const { data, loading, error } = useGetAllRequestsShopQuery({
    variables: {
      shopId: shopId ?? 0,
      filter: getSearchFilters(searchTerm),
      orderBy:
        sortField !== "fleet.name"
          ? generateOrderBy(sortField, sortDirection)
          : [
              { workflowType: sortDirection },
              generateOrderBy(sortField, sortDirection),
            ], // Keep ServiceUp items grouped together
      datesForClosed: yesterday,
    },
    pollInterval: 10_000,
    skip: !shopId,
  });

  useEffect(() => {
    if (!data?.requests.length) return;
    const requestIds = data?.requests?.map((request) => request.id);
    setRequestIds(requestIds);
  }, [data?.requests, setRequestIds]);

  const rows =
    data?.requests?.map((request) => ({
      Image: {
        vehicleId: request?.vehicle?.id,
        imageUrl: request?.vehicle?.imageUrl,
      },
      id: request?.id,
      unreadMessageCount: getUnreadMessagesCount(request?.id),
      "vehicle.year": getPrettyString([
        request?.vehicle.year,
        request?.vehicle.make,
        request?.vehicle.model,
      ]),
      "vehicle.plate": request?.vehicle?.plate,
      "vehicle.mileage": request?.vehicle?.mileage,
      fleet: request?.fleet,
      workflowType: request?.workflowType,
      createdAt: getPrettyTime(request?.createdAt),
      status: request?.status,
    })) ?? [];

  const handleFilterModelChange = useCallback(
    (gridFilterModel: GridFilterModel) => {
      const newTerm = gridFilterModel?.quickFilterValues?.[0];
      setSearchTerm(newTerm ?? "");
    },
    []
  );

  const tableId = "shops-orders";
  return (
    <UnreadRequestsProvider>
      <Box px={{ xs: 0, md: 2 }}>
        <Typography variant="h6" mb={2}>
          Orders
        </Typography>
        {!error ? (
          <DataGridTable
            id={tableId}
            apiRef={tableApiRef}
            columns={columns}
            rows={rows}
            loading={loading}
            handleSortChange={handleSortModelChange}
            toolbarFilter={{ handleFilterChange: handleFilterModelChange }}
            pinnedColumns={{ right: ["status"] }}
            defaultMobileColumns={["id", "vehicle.year", "status"]}
          />
        ) : (
          <ErrorMessage resourceName={"orders"} />
        )}
      </Box>
    </UnreadRequestsProvider>
  );
}
