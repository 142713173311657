import { Dispatch, SetStateAction } from "react";

import { Button, Chip, Typography } from "@mui/material";

import CollapsibleTable from "shared/components/CollapsibleTable/CollapsibleTable";
import UnreadMessageBadge from "shared/components/CollapsibleTable/UnreadMessageBadge";
import {
  CollapsibleTableHeader,
  SortOrderBy,
} from "shared/components/CollapsibleTable/types";
import {
  createLabel,
  createTableColumn,
  createTableHeader,
} from "shared/components/CollapsibleTable/utils";
import StyledLink from "shared/components/StyledLink";
import TextWithTooltip from "shared/components/tooltips/TextWithTooltip";
import VehicleImage, {
  VehicleImageHeight,
  VehicleImageWidth,
} from "shared/components/vehicles/VehicleImage";
import { GetAllRequestsByGroupShopQuery } from "shared/generated/graphql";
import { getPrettyTime } from "shared/lib/utils";
import { useUnreadRequests } from "shared/providers/UnreadRequestsProvider";

import { WorkflowType } from "../../../../../backend/common/workflow.types";

import { ctaStatuses, statusTextMap } from "./util";

type RequestTableType =
  | GetAllRequestsByGroupShopQuery["requestsDone"][0]
  | GetAllRequestsByGroupShopQuery["requestsInProgress"][0]
  | GetAllRequestsByGroupShopQuery["requestsNew"][0];

const RequestsDashboardTable = ({
  requests,
  title,
  loading,
  orderBy,
  setOrderBy,
}: {
  requests: RequestTableType[] | undefined;
  title: string;
  loading?: boolean;
  orderBy?: SortOrderBy;
  setOrderBy?: Dispatch<SetStateAction<SortOrderBy>>;
}) => {
  const { getUnreadMessagesCount } = useUnreadRequests();
  const labels = {
    image: createLabel("image", ""),
    order: createLabel("id", "Order"),
    vehicle: createLabel("vehicle.year", "YMM"),
    licensePlate: createLabel("vehicle.plate", "License Plate"),
    mileage: createLabel("vehicle.mileage", "Mileage"),
    requestedBy: createLabel("fleet.name", "Requested By"),
    createdAt: createLabel("createdAt", "Date & Time"),
    status: createLabel("status", "Status"),
  };

  const cellContent = (request: RequestTableType) => {
    return {
      vehicleImage: (
        <VehicleImage
          imageUrl={request.vehicle?.imageUrl}
          widthUsage={VehicleImageWidth.thumbnail}
          heightUsage={VehicleImageHeight.thumbnail}
          sx={{ transform: "scaleX(-1)" }}
        />
      ),
      order: (
        <UnreadMessageBadge
          content={
            <StyledLink href={`requests/${request.id}`}>
              {request.id}
            </StyledLink>
          }
          unreadMessageCount={getUnreadMessagesCount(Number(request.id))}
        />
      ),
      vehicle: (
        <TextWithTooltip
          text={`${request.vehicle?.year} ${request.vehicle?.make} ${request.vehicle?.model}`}
          variant="body2"
        />
      ),
      licensePlate: (
        <TextWithTooltip
          text={request.vehicle?.plate ? request.vehicle?.plate : "--"}
          variant="body2"
        />
      ),
      mileage: (
        <TextWithTooltip
          text={request.vehicle?.mileage ? `${request.vehicle?.mileage}` : "--"}
          variant="body2"
        />
      ),
      requestedBy: (
        <TextWithTooltip
          text={
            request.workflowType === WorkflowType.SAAS
              ? `${request.fleet?.name}`
              : "ServiceUp"
          }
          variant="body2"
        />
      ),
      createdAt: (
        <Typography variant="body2">
          {getPrettyTime(request.createdAt)}
        </Typography>
      ),
      status: ctaStatuses?.includes(request?.status) ? (
        <Button
          variant="contained"
          size="small"
          href={`requests/${request.id}`}
        >
          <TextWithTooltip
            text={statusTextMap.get(request.status)}
            variant="inherit"
          />
        </Button>
      ) : (
        <Chip
          variant="outlined"
          size="small"
          label={statusTextMap.get(request.status)}
          sx={{ maxWidth: { xs: "160px", sm: "100%" } }}
        />
      ),
    };
  };
  const mobileColumns = (request: RequestTableType) => [
    createTableColumn(labels.image.name, cellContent(request).vehicleImage),
    createTableColumn(labels.order.name, cellContent(request).order),
    createTableColumn(labels.status.name, cellContent(request).status),
  ];
  const mobileSubHeaders = [
    createTableHeader(labels.vehicle.name, labels.vehicle.label),
    createTableHeader(labels.licensePlate.name, labels.licensePlate.label),
    createTableHeader(labels.mileage.name, labels.mileage.label),
    createTableHeader(labels.requestedBy.name, labels.requestedBy.label),
    createTableHeader(labels.createdAt.name, labels.createdAt.label),
  ];
  const mobileSubColumns = (request: RequestTableType) => [
    createTableColumn(labels.vehicle.name, cellContent(request).vehicle),
    createTableColumn(
      labels.licensePlate.name,
      cellContent(request).licensePlate
    ),
    createTableColumn(labels.mileage.name, cellContent(request).mileage),
    createTableColumn(
      labels.requestedBy.name,
      cellContent(request).requestedBy
    ),
    createTableColumn(labels.createdAt.name, cellContent(request).createdAt),
  ];
  const tabletHeaders = [
    createTableHeader(labels.image.name, labels.image.label),
    createTableHeader(labels.order.name, labels.order.label, true),
    createTableHeader(labels.vehicle.name, labels.vehicle.label, true),
    createTableHeader(
      labels.licensePlate.name,
      labels.licensePlate.label,
      true
    ),
    createTableHeader(labels.status.name, labels.status.label, true),
  ];
  const tabletColumns = (request: RequestTableType) => [
    createTableColumn(labels.image.name, cellContent(request).vehicleImage),
    createTableColumn(labels.order.name, cellContent(request).order),
    createTableColumn(labels.vehicle.name, cellContent(request).vehicle),
    createTableColumn(
      labels.licensePlate.name,
      cellContent(request).licensePlate
    ),
    createTableColumn(labels.status.name, cellContent(request).status),
  ];
  const tabletSubHeaders: CollapsibleTableHeader[] = [
    createTableHeader(labels.mileage.name, labels.mileage.label),
    createTableHeader(labels.requestedBy.name, labels.requestedBy.label),
    createTableHeader(labels.createdAt.name, labels.createdAt.label),
  ];
  const tabletSubColumns = (request: RequestTableType) => [
    createTableColumn(labels.mileage.name, cellContent(request).mileage),
    createTableColumn(
      labels.requestedBy.name,
      cellContent(request).requestedBy
    ),
    createTableColumn(labels.createdAt.name, cellContent(request).createdAt),
  ];
  const desktopHeaders: CollapsibleTableHeader[] = [
    createTableHeader(labels.image.name, labels.image.label),
    createTableHeader(labels.order.name, labels.order.label, true),
    createTableHeader(labels.vehicle.name, labels.vehicle.label, true),
    createTableHeader(
      labels.licensePlate.name,
      labels.licensePlate.label,
      true
    ),
    createTableHeader(labels.mileage.name, labels.mileage.label, true),
    createTableHeader(labels.requestedBy.name, labels.requestedBy.label, true),
    createTableHeader(labels.createdAt.name, labels.createdAt.label, true),
    createTableHeader(labels.status.name, labels.status.label, true),
  ];
  const desktopColumns = (request: RequestTableType) => [
    createTableColumn(labels.image.name, cellContent(request).vehicleImage),
    createTableColumn(labels.order.name, cellContent(request).order),
    createTableColumn(labels.vehicle.name, cellContent(request).vehicle),
    createTableColumn(
      labels.licensePlate.name,
      cellContent(request).licensePlate
    ),
    createTableColumn(labels.mileage.name, cellContent(request).mileage),
    createTableColumn(
      labels.requestedBy.name,
      cellContent(request).requestedBy
    ),
    createTableColumn(labels.createdAt.name, cellContent(request).createdAt),
    createTableColumn(labels.status.name, cellContent(request).status),
  ];

  const dynamicRedirectTo = (id: string | number | null | undefined) =>
    `/requests/${id}`;

  return (
    <CollapsibleTable
      title={title}
      items={requests}
      loading={loading}
      desktopData={{ headers: desktopHeaders, columns: desktopColumns }}
      tabletData={{
        headers: tabletHeaders,
        columns: tabletColumns,
        subHeaders: tabletSubHeaders,
        subColumns: tabletSubColumns,
      }}
      mobileData={{
        columns: mobileColumns,
        subHeaders: mobileSubHeaders,
        subColumns: mobileSubColumns,
      }}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      redirectToTemplate={dynamicRedirectTo}
      displayEmptyState={false}
    />
  );
};

export default RequestsDashboardTable;
